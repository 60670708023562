import { v4 as uuid, } from "uuid"
import {
  SceneApi,
  TagApi,
  UserApi,
  SceneListItem as ApiSceneListItem,
  Scene as ApiScene,
  User as ApiUser,
  Configuration,
} from "utils/api_client"
import SceneListItem from "./models/SceneListItem"
import Scene from "./models/Scene"
import User from "./models/User"
import Tag from "./models/Tag"
import Me from "./models/Me"
import axios from "axios"
import { getPolyLicensesFromXml, } from "utils/scene_xml"

/**
 * This is the latest API client for this project.
 * Please add new apis here.
 */
export default class ApiClient {
  // Storing v1 path
  basePath: string
  sceneApiClient: SceneApi
  tagApiClient: TagApi
  userApiClient: UserApi

  /**
   * @param {string} apiBasePath Base path of API e.g. https://api.com/api/v1
   */
  constructor (apiBasePath: string) {
    this.basePath = apiBasePath
    const basePath = apiBasePath.replace("v1", "v2")
    const config = new Configuration({
      basePath,
      baseOptions: {
        withCredentials: true,
      },
    })
    this.sceneApiClient = new SceneApi(config)
    this.tagApiClient = new TagApi(config)
    this.userApiClient = new UserApi(config)
  }

  async getMe () {
    const res = await this.userApiClient.getMe()
    const user = res.data
    return {
      id: user.id,
      username: user.username,
      email: user.email,
      profileImageUrl: user.profile_image_url || null,
      siteUrl: user.site_url || "",
      facebookId: user.facebook_id || "",
      instagramId: user.instagram_id || "",
      twitterId: user.twitter_id || "",
      youtubeChannelUrl: user.youtube_channel_url,
      apiKey: user.api_key,
      plan: user.plan,
      features: {
        canChangePlan: user.features.can_change_plan,
        canManageLocations: user.features.can_manage_locations,
        mobileSkipTop: user.features.mobile_skip_top,
      },
    } as Me
  }

  async getScene (id: string) {
    const res = await this.sceneApiClient.getScene(id)
    return convertScene(res.data)
  }

  /**
   * Searches scenes.
   */
  async searchScenes (query = "", limit = 100, offset = 0) {
    const res = await this.sceneApiClient.search(query, limit, offset)
    return {
      scenes: convertSceneList(res.data.scenes),
      total: res.data.total,
    }
  }

  /**
   * 自身がアップロードしたシーンのリストを取得
   */
  async getMyScenes (query = "", limit = 100, offset = 0) {
    const res = await this.sceneApiClient.getMyScenes(query, limit, offset)
    return {
      scenes: convertSceneList(res.data.scenes),
      total: res.data.total,
    }
  }

  async getPublicScenesByIds (ids: Array<string>) {
    const res = await this.sceneApiClient.getPublicScenesByIds({ scene_ids: ids, })
    return convertSceneList(res.data.scenes)
  }

  async getUserById (id: number): Promise<User> {
    const res = await this.userApiClient.getUser(id)
    const user = res.data
    return convertUser(user)
  }

  async getScenesByUserId (id: number, limit = 100, offset = 0) {
    const res = await this.sceneApiClient.getScenesByUserId(id, limit, offset)
    return {
      scenes: convertSceneList(res.data.scenes),
      total: res.data.total,
    }
  }

  async getSceneXml (id: string) {
    const res = await axios.get(`${this.basePath}/scene/${id}`)
    const xml = res.data.sc_xml
    if (xml) {
      return xml
    } else {
      return null
    }
  }

  async getSceneLicenses (id: string) {
    const xml = await this.getSceneXml(id)
    if (xml) {
      return getPolyLicensesFromXml(xml)
    } else {
      return null
    }
  }

  async getRecemmendedScenesBySceneId (id: string, limit = 6) {
    const scene = await this.getScene(id)
    let scenes = [] as SceneListItem[]
    const userScenes = await this.getScenesByUserId(scene.user.id, limit)
    if (userScenes.scenes.length < limit) {
      scenes = userScenes.scenes
      if (scene.tags.length > 0) {
        const searchScenes = await this.searchScenes(scene.tags[0].text, limit)
        scenes = scenes.concat(searchScenes.scenes.slice(0, limit - scenes.length))
      }
    } else {
      scenes = userScenes.scenes.splice(0, limit)
    }
    return scenes
  }

  async getFollowersByUserId (id: number, limit = 100, offset = 0) {
    const res = await this.userApiClient.getFollowers(id, limit, offset)
    const users = res.data.users.map((user) => {
      return convertUser(user)
    })
    return {
      users,
      total: res.data.total,
    }
  }

  async getFolloweesByUserId (id: number, limit = 100, offset = 0) {
    const res = await this.userApiClient.getFollowees(id, limit, offset)
    const users = res.data.users.map((user) => {
      return convertUser(user)
    })
    return {
      users,
      total: res.data.total,
    }
  }

  async checkFollowing (id: number): Promise<boolean> {
    const res = await this.userApiClient.checkFollowing(id)
    return res.data.following
  }
}

const anonymousUser = {
  id: -1,
  username: "anonymous",
  profileImageUrl: null,
  facebookId: null,
  instagramId: null,
  twitterId: null,
  siteUrl: null,
  youtubeChannelUrl: null,
  followerCount: 0,
}

function convertUser (user: ApiUser): User {
  return {
    id: user.id,
    username: user.username,
    profileImageUrl: user.profile_image_url || null,
    facebookId: user.facebook_id || null,
    instagramId: user.instagram_id || null,
    twitterId: user.twitter_id || null,
    siteUrl: user.site_url || null,
    youtubeChannelUrl: user.youtube_channel_url || null,
    followerCount: 0,
  }
}

function convertScene (apiScene: ApiScene): Scene {
  let user: User = anonymousUser
  if (apiScene.user) {
    user = {
      id: apiScene.user.id,
      username: apiScene.user.username,
      profileImageUrl: apiScene.user.profile_image_url || null,
      facebookId: apiScene.user.facebook_id || null,
      instagramId: apiScene.user.instagram_id || null,
      twitterId: apiScene.user.twitter_id || null,
      siteUrl: apiScene.user.site_url || null,
      youtubeChannelUrl: apiScene.user.youtube_channel_url || null,
      followerCount: 0,
    }
  }

  return {
    id: apiScene.id,
    user: user,
    title: apiScene.title || "",
    description: apiScene.description || "",
    thumbnail: {
      id: uuid(),
      url: apiScene.thumbnail_middle_url,
      width: 540,
      height: 960,
    },
    accessLevel: apiScene.access_level,
    published: apiScene.published,
    updatedAt: apiScene.updated_at,
    publishedAt: apiScene.published_at || null,
    gooded: apiScene.gooded || false,
    goodCount: apiScene.good_count || 0,
    mylisted: apiScene.mylisted || false,
    tags: apiScene.tags?.map(t => {
      return {
        id: t.id,
        text: t.text,
      } as Tag
    }) ?? [],
  }
}

function convertSceneList (apiScenes: ApiSceneListItem[]): SceneListItem[] {
  return apiScenes.map((scene): SceneListItem => {
    let user: User = anonymousUser
    if (scene.user) {
      const profileImageUrl = scene.user.profile_image_url ? scene.user.profile_image_url + "?fm=jpg&q=0" : null
      user = {
        id: scene.user.id,
        username: scene.user.username,
        profileImageUrl,
        facebookId: scene.user.facebook_id || null,
        instagramId: scene.user.instagram_id || null,
        twitterId: scene.user.twitter_id || null,
        siteUrl: scene.user.site_url || null,
        youtubeChannelUrl: scene.user.youtube_channel_url || null,
        followerCount: 0,
      }
    }

    const thumbnailUrl = scene.thumbnail_middle_url ? scene.thumbnail_middle_url + "?fm=jpg&q=0" : ""

    return {
      id: scene.id,
      user: user,
      title: scene.title,
      thumbnail: {
        id: uuid(),
        url: thumbnailUrl,
        width: 540,
        height: 960,
      },
      createdAt: scene.created_at,
      updatedAt: scene.updated_at,
      publishedAt: scene.published_at,
      gooded: scene.gooded,
      mylisted: scene.mylisted,
      accessLevel: scene.access_level,
      published: scene.published,
    }
  })
}
