import React, { useCallback, } from "react"
import { ConnectedProps, connect, } from "react-redux"
import { RouteComponentProps, } from "react-router-dom"
import { tunnelClient, } from "globalInstance"
import style from "./style.module.scss"

const mapDispatch = {
}

const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{id: string}>

const SessionPage = ({
  match,
}: Props) => {
  const { id, } = match.params
  const onStartClick = useCallback(async () => {
    try {
      await tunnelClient.joinSession(id)
    } catch (e) {
      console.error("failed to join")
    }
  }, [id])
  return (
    <div className={style.frame}>
      <button onClick={onStartClick}>start</button>
    </div>
  )
}

export default connector(SessionPage)
